import './App.css'
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { blue } from '@mui/material/colors'
import LogoutIcon from '@mui/icons-material/Logout'
import LoanAndCash from './components/LoanAndCash/LoanAndCash'
import SunRun from './components/SunRun/SunRun'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from './components/Select'
import Box from '@mui/material/Box'
import AuthenticationModal from './components/AuthenticationModal'
import LeaderShipLevel from './components/LeaderShipLevel'
import RepTenure from './components/RepTenure'
import { useCookies } from 'react-cookie'
import AdminPortal from './components/Admin/AdminPortal'
import { TextField, InputAdornment} from '@mui/material'

function App() {
  const [market, setMarket] = useState('')
  const [installer, setInstaller] = useState('')
  const [installerValues, setInstallerValues] = useState([])
  const [contractSignDate, setContractSignDate] = useState(new Date())
  const [installerBaseline, setInstallerBaseline] = useState(0)
  const [paymentCalculationsToDisplay, setPaymentCalculationsToDisplay] = useState([])
  const [paymentCalculations, setPaymentCalculations] = useState([])
  const [repTenureValues, setRepTenureValues] = useState([])
  const [repTenure, setRepTenure] = useState()
  const [product, setProduct] = useState('')
  const [GSFormValues, setGSFormValues] = useState({marketValues: [], installerValues: []})
  const [netPricePerWatt, setNetPricePerWatt] = useState(3.6)
  const [systemSizeKW, setSystemSizeKW] = useState('')
  const [lead, setLead] = useState(false)
  const [deductions, setDeductions] = useState(0)
  const [netPricePerWattValues, setNetPricePerWattValues] = useState([])
  const [tcrVariables, setTcrVariables] = useState({})
  const [estimatedInstalls, setEstimatedInstalls] = useState(1)
  const [selectedPayScale, setSelectedPayScale] = useState({totalCommission: 0})
  const [escalator, setEscalator] = useState(0)
  const [EPCValues, setEPCValues] = useState([])
  const [EPCOptions, setEPCOptions] = useState([])
  const [epcRedline, setEpcRedline] = useState(0)
  const [sunRunMarketValues, setSunRunMarketValues] = useState([])
  const [loanAndCashMarketValues, setLoanAndCashMarketValues] = useState([])
  const [authenticated, setAuthenticated] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [userType, setUserType] = useState('')
  const [marketEscalatorValues, setMarketEscalatorValues] = useState([])
  const [sunRunCalculationsToDisplay, setSunRunCalculationsToDisplay] = useState([])
  const [leadershipLevel, setLeadershipLevel] = useState([])
  const [adminRepTenureValues, setAdminRepTenureValues] = useState([])
  const [calculationsSubmitted, setCalculationsSubmitted] = useState(false)
  const [sunRunCalculationsSubmitted, setSunRunCalculationsSubmitted] = useState(false)
  const [adminPassword, setAdminPassword] = useState()
  const [cookies, setCookie, removeCookie] = useCookies(['authenticate', 'userType', 'password'])
  const [customElements, setCustomElements] = useState([])
  const [tcrPercentage, setTCRPercentage] = useState(50)
  const NEW_CALC_DATE = new Date('4/1/2023')

  const calculatePayment = async (market, installer, product, contractSignDate) => {
    await setPaymentCalculations([])

    let res = await fetch(`/api/calculate_payment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userInputs: {
          installer: installer,
          market: market,
          product: product,
          contractSignDate: contractSignDate
        }
      })
    })
      .then(res => res.json())
      .then(json => {
        setInstallerBaseline(json.A)
        setEpcRedline(json.epc_redline)
        setTcrVariables(json.tcrVariables)

        return {installerBaseline: json.A, tcrVariables: json.tcrVariables}
      })

      return res
  }

  const buildTablePaymentCalculations = async (userInputs, netPricePerWattValues, tcrVariables, systemSizeKW) => {
    if(repTenureValues.length < 1 && leadershipLevel.length < 1 && customElements.length < 1 || !cookies.authenticate || !cookies.password) {
      authenticate()
    } else {
      let A = Number(userInputs.installerBaseline)
      let C = Number(userInputs.systemSizeKW)
      let D = Number(userInputs.deductions)
      let E = Number(tcrVariables.tcrConstant)
      let F = Number(tcrVariables.tcrAdjuster)

      let calculations = netPricePerWattValues.map(B => {
        let paymentCalculation = crunchNumbers(A, B, C, D, E, F, userInputs.repTenure, userInputs.lead, userInputs.installer, userInputs.epcRedline, userInputs.contractSignDate)

        return paymentCalculation
      })
      let selection = calculations.filter(c => Number(c.nppw) == Number(userInputs.netPricePerWatt))[0]

      setPaymentCalculations(calculations)
      grabTenTotalCommissionValues(calculations, userInputs.netPricePerWatt)
      setSelectedPayScale(selection)
      setCalculationsSubmitted(true)
    }
  }

  const getTcrVariables = (userInputs) => {
    return fetch(`/api/get_variables`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userInputs: {
          installer: installer,
          market: market,
          product: product,
          contractSignDate: contractSignDate
        }
      })
    })
      .then(res => res.json())
      .then(json => {
        setTcrVariables(json)

        return json
      })
  }

  const buildSunRunTableCalculations = async (userInputs, tcrVariables, systemSizeKW, marketEscalatorValues, escalator) => {
    if(repTenureValues.length < 1 && leadershipLevel.length < 1  && customElements.length < 1 || !cookies.authenticate || !cookies.password) {
      authenticate()
    } else {
      let variables = await getTcrVariables({installer: userInputs.installer})
      let C = Number(systemSizeKW)
      let D = Number(userInputs.deductions)
      let E = Number(variables.tcrConstant)
      let F = Number(variables.tcrAdjuster)
      let repTenure = userInputs.repTenure

      console.log('marketEscalatorValues', marketEscalatorValues)
      let calculations = marketEscalatorValues.filter(x => x[1] == escalator).map(row => {
        let A = row[11]

        let tableValues = row.filter((rV, i) => i >= 3 && i <= 10)

        return tableValues.map(x => {
          let B = Number(x)
          let paymentCalculation = crunchNumbers(A, B, C, D, E, F, repTenure, userInputs.lead, userInputs.installer, userInputs.epcRedline, userInputs.contractSignDate)

          return paymentCalculation
        })
      })
      setSunRunCalculationsToDisplay(calculations)
      setSunRunCalculationsSubmitted(true)

      return calculations
    }
  }

  const crunchNumbers = (A, B, C, D, E, F, repTenure, lead, installer, epc_redline, contractSignDate) => {
    console.log('--------------- New Calculation -------------')
    let sumOfLeadership = sumOfSelectedLeadershipValues()
    let salesRepSelected = leadershipLevel.filter(x => x.title == 'Sales Rep' && x.selected).length > 0
    console.log('salesRepSelected?', salesRepSelected)
    let expensesSelected = leadershipLevel.filter(x => x.title == 'Expenses' && x.selected).length > 0
    console.log('expensesSelected?', expensesSelected)
    let expensesPercentage = 0.75 - Number(repTenure)
    console.log('expensesPercentage', expensesPercentage)
    console.log('repTenure', repTenure)
    console.log('sum of leadership values', sumOfLeadership)
    let percentagesTotal = Number(repTenure) + Number(sumOfLeadership)
    console.log('sum of leadership + repTenure', percentagesTotal)
    percentagesTotal = !salesRepSelected && leadershipLevel.filter(x => x.selected).length > 0 ? Number(percentagesTotal) - Number(repTenure) : percentagesTotal
    console.log('minus repTenure if sales rep not selected', percentagesTotal)
    percentagesTotal = expensesSelected ? Number(percentagesTotal) + Number(expensesPercentage) : percentagesTotal
    console.log('plue expenses percentage', percentagesTotal)
    console.log('NetPricePerWatt', Number(B))
    console.log('Baseline', Number(A))
    console.log('System Size', Number(C))
    console.log('Deductions', Number(D))
    console.log('TCR Constant', Number(E))
    console.log('TCR Adjuster', Number(F))

    let paymentCalculation = calculatePaymentDynamically(A, B, C, D, E, F, percentagesTotal, installer, epc_redline, contractSignDate)
    console.log('paymentCalculation', paymentCalculation)

    paymentCalculation = lead ? paymentCalculation / 2 : paymentCalculation
    console.log('if lead? paymentCalculation / lead', paymentCalculation)

    const adjustedPaymentCalculation = adjustFinalPaymentCalculation(paymentCalculation)
    const commissionPerKW = (adjustedPaymentCalculation / C).toFixed(2)

    console.log('before reducing paymentCalculation by 3%', paymentCalculation)
    console.log('after reducing paymentCalculation by 3% and rounded down to multiple of 25', adjustedPaymentCalculation)
    console.log('FINAL commissionPerKW', commissionPerKW)

    return {totalCommission: adjustedPaymentCalculation, nppw: B, commissionPerKW: commissionPerKW}
  }

  const round = (number, increment, offset) => {
    return Math.floor((number - offset) / increment ) * increment + offset;
  }

  const adjustFinalPaymentCalculation = (paymentCalculation) => {
    return round(paymentCalculation * 0.97, 25, 0)
  }

  const calculatePaymentDynamically = (A, B, C, D, E, F, percentagesTotal, installer, epc_redline, contractSignDate) => {
    if(new Date(contractSignDate) >= NEW_CALC_DATE && product !== 1) {
      const freedom_var = installer == 'Freedom Forever' ? 0.8 : 1
      const redlineWbaseline = Number(A) > 0 ? epc_redline - Number(A) : 0

      console.log('installer:', installer, ', freedom var:', freedom_var)
      console.log('epcRedline:', epc_redline, ', baseline:', Number(A), ', redlineWbaseline:', redlineWbaseline)
      console.log("******** New Formula: **********")
      console.log(`(((NPPW: ${Number(B)} - EPC_REDLINE: ${Number(epc_redline)})`)
      console.log(`* freedom var: ${freedom_var})`)
      console.log(`* SystemSize: ${Number(C)} * 1000) = commission amount`)

      const commissionAmount = ((Number(B) - epc_redline) * freedom_var) * Number(C) * 1000

      console.log(`(commission amount: ${commissionAmount} + redline - baseline (if baseline is > 0) = (${redlineWbaseline}) * SystemSize: ${Number(C)} * 1000)` )
      console.log(`- (0.05 * commission amount ${commissionAmount}) = tcr`)

      const tcr = commissionAmount + ((redlineWbaseline) * Number(C) * 1000) - (0.05 * commissionAmount)

      console.log(`tcr ${tcr} - deductions: ${Number(D)}`)
      console.log(`* Total percentages: (Reps, Leadership, Expenses) ${Number(percentagesTotal)}`)
      console.log("****************************")

      return (tcr - Number(D)) * Number(percentagesTotal) 
    } else {
      console.log("******** Old Formula: **********")
      console.log(`(((NPPW: ${Number(B)} - Baseline: ${Number(A)})`)
      console.log(`* SystemSize: ${Number(C)} * 1000 * TCR Const: ${Number(E)})`)
      console.log(`- (TCR Adj: ${Number(F)} + Deductions: ${Number(D)}))`)
      console.log(`* Total percentages: (Reps, Leadership, Expenses) ${Number(percentagesTotal)}`)
      console.log("****************************")

      return (((Number(B) - Number(A)) * Number(C) * 1000 * Number(E)) - (Number(F) + Number(D))) * (Number(percentagesTotal))
    }
  }

  const sumOfSelectedLeadershipValues = () => {
    if (leadershipLevel.length == 0) {
      return 0
    } else {
      let selectedValues = leadershipLevel.map(x => {
        if (x.selected) {
          if (x.value) {
            return Number(x.value)
          } else {
            return 0
          }
        } else {
          return 0
        }
      })

      const initialValue = 0
      const finalSum = selectedValues.reduce((pV, cV) => pV + cV, initialValue)

      return finalSum
    }
  }

  const grabTenTotalCommissionValues = async (paymentCalculations, nppw) => {
    await setPaymentCalculationsToDisplay([])

    const activeRowIndex = paymentCalculations.findIndex((pc) => {
      return parseFloat(pc.nppw) === nppw;
    });

    const visibleRows = paymentCalculations.slice(activeRowIndex - 5, activeRowIndex + 6);

    setPaymentCalculationsToDisplay(visibleRows);
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#333'
      },
      secondary: {
        main: blue[100]
      }
    }
  })

  const handleInstaller = async (market, installer, product, contractSignDate) => {
    await setInstaller(installer)

    if (installer && market) {
      let res = await calculatePayment(market, installer, product, contractSignDate)

      if(calculationsSubmitted && res && product != 1) {
        let installerBaseline = res.installerBaseline
        buildTablePaymentCalculations(
          {market, repTenure, product, systemSizeKW, netPricePerWatt, lead, deductions, contractSignDate, installerBaseline, installer, epcRedline, contractSignDate},
          netPricePerWattValues,
          res.tcrVariables,
          systemSizeKW
        )
      } else if (sunRunCalculationsSubmitted && res && product == 1) {
        buildSunRunTableCalculations(
          {market, repTenure, product, systemSizeKW, lead, deductions, contractSignDate, installerBaseline, epcRedline, installer},
          tcrVariables,
          systemSizeKW,
          marketEscalatorValues,
          escalator
        )
      }
    }
  }

  console.log(calculationsSubmitted)

  const handleContractSignDate = async (contractSignDate) => {
    console.log('hit handler for contract date', contractSignDate)
    setContractSignDate(contractSignDate)

    if (installer && market) {
      let res = await calculatePayment(market, installer, product, contractSignDate)

      if(calculationsSubmitted && product != 1) {
        let installerBaseline = res.installerBaseline

        buildTablePaymentCalculations(
          {market, repTenure, product, systemSizeKW, netPricePerWatt, lead, deductions, contractSignDate, installerBaseline, installer, epcRedline},
          netPricePerWattValues,
          res.tcrVariables,
          systemSizeKW
        ) 
      } else if (sunRunCalculationsSubmitted && res && product == 1) {
        buildSunRunTableCalculations(
          {market, repTenure, product, systemSizeKW, lead, deductions, contractSignDate, installerBaseline, epcRedline, installer},
          tcrVariables,
          systemSizeKW,
          marketEscalatorValues,
          escalator
        )
      }
    }
  }

  const handleLeadershipLevel = (leadershipLevel) => {
    setLeadershipLevel(leadershipLevel)

    if (product == 1 && sunRunCalculationsSubmitted) {
      buildSunRunTableCalculations({
        market,
        installer,
        repTenure,
        product,
        systemSizeKW,
        lead,
        deductions,
        contractSignDate,
        installerBaseline,
        epcRedline
      }, tcrVariables, systemSizeKW, marketEscalatorValues, escalator)
    } else if (product != 1 && calculationsSubmitted) {
      buildTablePaymentCalculations(
        {
          market,
          installer,
          repTenure,
          product,
          systemSizeKW,
          netPricePerWatt,
          lead,
          deductions,
          contractSignDate,
          installerBaseline,
          installer,
          epcRedline
        },
        netPricePerWattValues, tcrVariables, systemSizeKW
      )
    }
  }

  const handleMarket = async (market, EPCValues, product, marketValues) => {
    setMarket(market)

    if (product == 1) {
      console.log('EPCValues', EPCValues)
      let marketEscalatorValues = EPCValues.filter(x => x[0] == market)
      console.log('filtered', marketEscalatorValues)

      setMarketEscalatorValues(marketEscalatorValues)

      let data = [...new Set(marketEscalatorValues.map(x => x[1]))]
      console.log('no duplicates', data)

      setEPCOptions(data)

      if(sunRunCalculationsSubmitted) {
        buildSunRunTableCalculations({
          market,
          installer,
          repTenure,
          product,
          systemSizeKW,
          lead,
          deductions,
          contractSignDate,
          installerBaseline,
          epcRedline
        }, tcrVariables, systemSizeKW, marketEscalatorValues, escalator) 
      }
    } else {
      let selectedMarketInstallerVals = marketValues.filter(x => x.market == market).map(x => x.installer).sort()
      let uniqueInstallerVals = [...new Set(selectedMarketInstallerVals)]

      setInstallerValues(uniqueInstallerVals)

      if(calculationsSubmitted && installer) {
        let res = await calculatePayment(market, installer, product, contractSignDate)
        let installerBaseline = res.installerBaseline

        buildTablePaymentCalculations(
          {market, repTenure, product, systemSizeKW, netPricePerWatt, lead, deductions, contractSignDate, installerBaseline, installer, epcRedline},
          netPricePerWattValues,
          res.tcrVariables,
          systemSizeKW
        ) 
      }
    }
  }

  const handleNetPricePerWatt = async (netPricePerWatt) => {
    await setNetPricePerWatt(netPricePerWatt)

    let start_nppw = Number(netPricePerWatt) - 0.25
    let end_nppw = Number(netPricePerWatt) + 0.25
    let netPricePerWattValues = []

    for (let i = start_nppw; i < end_nppw; i += 0.05) {
      netPricePerWattValues.push(i.toFixed(2))
    }

    setNetPricePerWattValues(netPricePerWattValues)

    if (calculationsSubmitted) {
      buildTablePaymentCalculations(
        {market, repTenure, product, systemSizeKW, netPricePerWatt, lead, deductions, contractSignDate, installerBaseline, installer, epcRedline},
        netPricePerWattValues,
        tcrVariables,
        systemSizeKW
      ) 
    }
  }

  const handleSystemSizeKW = (systemSizeKW) => {
    setSystemSizeKW(systemSizeKW)

    if (product != 1 && calculationsSubmitted){
      buildTablePaymentCalculations(
        {market, repTenure, product, systemSizeKW, netPricePerWatt, lead, deductions, contractSignDate, installerBaseline, installer, epcRedline, contractSignDate},
        netPricePerWattValues,
        tcrVariables,
        systemSizeKW
      )
    } else if (sunRunCalculationsSubmitted) {
      buildSunRunTableCalculations({
        market,
        installer,
        repTenure,
        product,
        systemSizeKW,
        lead,
        deductions,
        contractSignDate,
        installerBaseline,
        epcRedline
      }, tcrVariables, systemSizeKW, marketEscalatorValues, escalator)  
    }
  }

  const handleLead = (lead) => {
    setLead(lead)

    if(calculationsSubmitted && product != 1) {
      buildTablePaymentCalculations(
        {market, repTenure, product, systemSizeKW, netPricePerWatt, lead, deductions, contractSignDate, installerBaseline, installer, epcRedline, contractSignDate},
        netPricePerWattValues,
        tcrVariables,
        systemSizeKW
      )
    } else if (sunRunCalculationsSubmitted) {
      buildSunRunTableCalculations({
        market,
        installer,
        repTenure,
        product,
        systemSizeKW,
        lead,
        deductions,
        contractSignDate,
        installerBaseline,
        epcRedline
      }, tcrVariables, systemSizeKW, marketEscalatorValues, escalator) 
    }
  }

  const handleDeductions = (deductions) => {
    setDeductions(deductions)

    if(calculationsSubmitted && product != 1) {
      buildTablePaymentCalculations(
        {market, repTenure, product, systemSizeKW, netPricePerWatt, lead, deductions, contractSignDate, installerBaseline, installer, epcRedline},
        netPricePerWattValues,
        tcrVariables,
        systemSizeKW
      )
    } else if (sunRunCalculationsSubmitted) {
      buildSunRunTableCalculations({
        market,
        installer,
        repTenure,
        product,
        systemSizeKW,
        lead,
        deductions,
        contractSignDate,
        installerBaseline,
        epcRedline
      }, tcrVariables, systemSizeKW, marketEscalatorValues, escalator) 
    }
  }

  const handleEstimatedInstalls = (estimatedInstalls) => {
    setEstimatedInstalls(estimatedInstalls)
  }

  const handleTCRPercentage = (tcrPercentage) => {
    setTCRPercentage(tcrPercentage)
    const rt = Number(tcrPercentage) / 100

    handleRepTenure(rt)
  }

  const handleRepTenure = async (repTenure) => {
    setRepTenure(repTenure)

    if(calculationsSubmitted && product != 1) {
      buildTablePaymentCalculations(
        {market, repTenure, product, systemSizeKW, netPricePerWatt, lead, deductions, contractSignDate, installerBaseline, installer, epcRedline},
        netPricePerWattValues,
        tcrVariables,
        systemSizeKW
      ) 
    } else if (sunRunCalculationsSubmitted) {
      buildSunRunTableCalculations({
        market,
        installer,
        repTenure,
        product,
        systemSizeKW,
        lead,
        deductions,
        contractSignDate,
        installerBaseline,
        epcRedline
      }, tcrVariables, systemSizeKW, marketEscalatorValues, escalator) 
    }
  }

  const handleRepTenureValues = (repTenureValues) => {
    setRepTenureValues(repTenureValues)
  }

  const handleProduct = (market, installer, product, installDate, loanAndCashMarketValues, sunRunMarketValues) => {
    setProduct(product)

    if (product == 1) {
      setGSFormValues({marketValues: sunRunMarketValues, installerValues: ['Freedom Forever']})
    } else {
      setGSFormValues(loanAndCashMarketValues)
    }

    if (installer && market) {
      calculatePayment(market, installer, product, installDate)
    }
  }

  const handleEscalator = (escalator) => {
    setEscalator(escalator)

    if(sunRunCalculationsSubmitted) {
      buildSunRunTableCalculations(
        {market, installer, repTenure, product, systemSizeKW, lead, deductions, contractSignDate, installerBaseline, epcRedline},
        tcrVariables,
        systemSizeKW,
        marketEscalatorValues,
        escalator
      )
    }
  }

  const handleEPCValues = (EPCValues) => {
    setEPCValues(EPCValues)
  }

  const getFormData = (product) => {
    fetch(`/api/form_values?product=${product}`)
      .then(res => res.json())
      .then(json => {
        setGSFormValues(json)
        let marketVals = json.marketValues.map(x => x.market)
        let uniqMarketVals = [...new Set(marketVals)]
        setLoanAndCashMarketValues(uniqMarketVals)
      })
  }

  const authenticate = (password) => {
    if(password) {
      fetch(`/api/authenticate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({password, contractSignDate})
      })
        .then(res => {
          if (res.status == 200) {
            setAuthenticated(true)

            let expireDate = new Date()
            expireDate.setDate(expireDate.getDate() + 7)
            setCookie('authenticate', true, {path: '/', expires: expireDate})
            setCookie('password', password, {path: '/', expires: expireDate})

          } else {
            logout()
            setErrorMessage('Failed to Authenticate')
          }
          return res.json()
        })
        .then(json => {
          const repTenureValues = json.buttons.filter(x => x.type === "Rep Tenure")
          const leadershipLevels = json.buttons.filter(x => x.type === "Leadership Level")
          const customElements = json.buttons.filter(x => x.type === "Custom Element")
          const userType = json.userType
          let expireDate = new Date()

          const build = leadershipLevels.map(x => {
            if(x.title == "Sales Rep") {
              return {...x, selected: true}
            } else {
              return {...x, selected: false}
            }
          })

          expireDate.setDate(expireDate.getDate() + 7)
          setCookie('userType', userType, {path: '/', expires: expireDate})

          setCustomElements(customElements)
          setRepTenureValues(repTenureValues)
          setDefaultRepTenure(repTenureValues, userType)
          handleLeadershipLevel(build)
          buildAdminRepTenureValues(repTenureValues)

          return json
        })
        .catch(error => error)
    } else {
      setErrorMessage('')
      logout()
    }
  }

  const buildAdminRepTenureValues = (repTenureValues) => {
    const buttons = repTenureValues.map(button => {
      return {title: button.title, value: button.value, type: button.type, selected: false}
    })

    setAdminRepTenureValues(buttons)

    return
  }
  
  const setDefaultRepTenure = (json, userType) => {
    if (cookies.userType == 'consultant') {
      let defaultRepTenure = json.filter(x => x.title == 'Consultant')[0].value
      setRepTenure(defaultRepTenure)
    } else if (cookies.userType == 'energy_pro') {
      let defaultRepTenure = json.filter(x => x.title == 'Energy Pro')[0].value
      setRepTenure(defaultRepTenure)
    } else if (cookies.userType == 'specialist') {
      let defaultRepTenure = json.filter(x => x.title == 'Specialist')[0].value
      setRepTenure(defaultRepTenure)
    } else if (json.length > 0 && !cookies.userType) {
      let defaultRepTenure = json[0].value
      setRepTenure(defaultRepTenure)
    } else {
      setRepTenure(0.5)
    }

    return
  }

  const createNewButtonVariables = (password, repButtons, leaderButtons) => {
    let buttons = []
    repButtons.forEach(x => {
      if(x.selected) {
        buttons.push(x)
      }
    })
    leaderButtons.forEach(x => {
      if(x.selected) {
        buttons.push(x)
      }
    })

    fetch(`/api/create_variable`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({password, buttons})
    }).then(res => {
      if(res.status == 200) {
        alert("Custom password created successfully")
        logout()
      } else {
        alert("Failed to create custom password")
      }
    })
  }

  const logout = () => {
    setAuthenticated(false)
    setUserType('')
    setErrorMessage('')
    setLeadershipLevel([])
    setRepTenureValues([])
    removeCookie('authenticate')
    removeCookie('userType')
    removeCookie('password')
    setCalculationsSubmitted(false)
  }

  useEffect(() => {
    if(repTenureValues.length < 1 && leadershipLevel < 1 || !cookies.authenticate) {
       authenticate(cookies.password) 
    }
    getFormData(undefined)

    let start_nppw = 1.50
    let end_nppw = 6.05
    let values = []

    for (let i = start_nppw; i < end_nppw; i += 0.05) {
      values.push(i.toFixed(2))
    }

    setNetPricePerWattValues(values)

    if (calculationsSubmitted) {
      buildTablePaymentCalculations(
        {
          market,
          installer,
          repTenure,
          product,
          systemSizeKW,
          netPricePerWatt,
          lead,
          deductions,
          contractSignDate,
          installerBaseline,
          installer,
          epcRedline
        },
        netPricePerWattValues,
        tcrVariables,
        systemSizeKW
      )
    }
  }, [])

  return (
    <div className="flex-column flex-ai-center width70 flex-jc-center">
      <ThemeProvider theme={theme}>
        {!authenticated ? <AuthenticationModal authenticate={authenticate} authenticated={authenticated}
                                               errorMessage={errorMessage}/> : null}
        <div id="background-image">
          {authenticated ?
            <LogoutIcon
            className="absolute top-20 right-35 color-white cursor-pointer"
            onClick = {() => logout()}
            /> : null}
        </div>
        <div id="vivint-energy-img"></div>
        { cookies.userType === "admin" ?
          <div id="calculator" className="width100 flex-column flex-ai-fs flex-jc-fs">
            <AdminPortal
              adminRepTenureValues={adminRepTenureValues}
              setAdminRepTenureValues={setAdminRepTenureValues}
              leadershipLevel={leadershipLevel}
              handleLeadershipLevel={handleLeadershipLevel}
              adminPassword={adminPassword}
              setAdminPassword={setAdminPassword}
              createNewButtonVariables={createNewButtonVariables}
            />
          </div>
        :
        <div id="calculator" className="width100 flex-column flex-ai-fs flex-jc-fs">
          <h3>Status Inputs</h3>
          <RepTenure
            handleRepTenureValues={handleRepTenureValues}
            repTenureValues={repTenureValues}
            repTenure={repTenure}
            handleRepTenure={handleRepTenure}
            userType={cookies.userType}
          />

          <LeaderShipLevel
            leadershipLevel={leadershipLevel}
            handleLeadershipLevel={handleLeadershipLevel}
          />

          {customElements.map((x,i) => ( 
            <div key={x.title + i + x.value} className="flex-column width100">
              <p>{x.title}</p>
              <div className="flex-row width100 flex-ai-fs">
              <TextField
                id="outlined-number-tcr"
                type="number"
                onChange={e => handleTCRPercentage(e.target.value)}
                value={tcrPercentage}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 1,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end"><p className="color-white">%</p></InputAdornment>
                }}
                sx={{width: '100%'}}
              />
              </div>
            </div>
           ))}

          <div className="flex-column width100">
            <Tooltip title="This is the type of contract you are selling to the customer.">
              <p>Product</p>
            </Tooltip>
            <div className="flex-row width100 flex-jc-sb">
              <Button className="width50 margin-right10" variant={product === '' ? 'contained' : 'outlined'}
                      onClick={() => handleProduct(market, installer, '', contractSignDate, loanAndCashMarketValues, sunRunMarketValues)}>Loan
                and Cash</Button>
              <Button
                className="width50 color-white"
                variant={product === 1 ? 'contained' : 'outlined'}
                onClick={() => handleProduct(
                  market, installer, 1, contractSignDate, loanAndCashMarketValues, sunRunMarketValues
                )}>SunRun</Button>
            </div>
          </div>

          <div className="flex-column width100">
            <p>Market</p>
            <div className="flex-row width100 flex-ai-fs">
              <Box sx={{minWidth: '100%'}}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={market}
                    onChange={e => handleMarket(e.target.value, EPCValues, product, GSFormValues.marketValues)}
                    className="width100"
                  >
                    {product != 1 ? loanAndCashMarketValues?.map((mv, i) => {
                      return (
                        <MenuItem key={mv + i} value={mv}>{mv}</MenuItem>
                      )
                    }) : sunRunMarketValues?.map((mv, i) => {
                      return (
                        <MenuItem key={mv + i} value={mv}>{mv}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>

          {product === '' ?
            <LoanAndCash
              installer={installer}
              handleInstaller={handleInstaller}
              GSFormValues={GSFormValues}
              netPricePerWatt={netPricePerWatt}
              handleNetPricePerWatt={handleNetPricePerWatt}
              systemSizeKW={systemSizeKW}
              handleSystemSizeKW={handleSystemSizeKW}
              handleLead={handleLead}
              handleDeductions={handleDeductions}
              lead={lead}
              deductions={deductions}
              market={market}
              repTenureValues={repTenureValues}
              repTenure={repTenure}
              product={product}
              contractSignDate={contractSignDate}
              setContractSignDate={handleContractSignDate}
              installerBaseline={installerBaseline}
              netPricePerWattValues={netPricePerWattValues}
              tcrVariables={tcrVariables}
              buildTablePaymentCalculations={buildTablePaymentCalculations}
              paymentCalculationsToDisplay={paymentCalculationsToDisplay}
              handleEstimatedInstalls={handleEstimatedInstalls}
              estimatedInstalls={estimatedInstalls}
              selectedPayScale={selectedPayScale}
              installerValues={installerValues}
              epcRedline={epcRedline}
            />
            :
            <SunRun
              handleInstaller={handleInstaller}
              contractSignDate={contractSignDate}
              setContractSignDate={handleContractSignDate}
              GSFormValues={GSFormValues}
              installer={installer}
              market={market}
              repTenureValues={repTenureValues}
              product={product}
              escalator={escalator}
              handleEscalator={handleEscalator}
              getFormData={getFormData}
              EPCOptions={EPCOptions}
              EPCValues={EPCValues}
              handleEPCValues={handleEPCValues}
              setGSFormValues={setGSFormValues}
              setSunRunMarketValues={setSunRunMarketValues}
              setEPCOptions={setEPCOptions}
              setInstaller={setInstaller}
              systemSizeKW={systemSizeKW}
              handleSystemSizeKW={handleSystemSizeKW}
              netPricePerWatt={netPricePerWatt}
              lead={lead}
              deductions={deductions}
              installerBaseline={installerBaseline}
              netPricePerWattValues={netPricePerWattValues}
              tcrVariables={tcrVariables}
              handleLead={handleLead}
              handleDeductions={handleDeductions}
              buildSunRunTableCalculations={buildSunRunTableCalculations}
              marketEscalatorValues={marketEscalatorValues}
              setMarketEscalatorValues={setMarketEscalatorValues}
              sunRunCalculationsToDisplay={sunRunCalculationsToDisplay}
              repTenure={repTenure}
              epcRedline={epcRedline}
            />
          }
        </div>
        }
      </ThemeProvider>
    </div>
  )
}

export default App
